import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { extractTimeSeriesDef } from 'data-explorer/data-explorer.utils'
import { Button, Icon, Text } from 'src/components/ui'
import { PCDimensionTs } from 'src/dex/analysis'
import { SavedTimeSeries } from 'src/dex/time_series'

type Props = {
  dimensions: PCDimensionTs[]
  workspaceTimeSeries: SavedTimeSeries[]
}

export function TimeSeriesDimensionsDisplay({
  dimensions,
  workspaceTimeSeries,
}: Props): JSX.Element {
  return (
    <>
      {dimensions.map((dimension, index) => {
        const timeSeries = dimension.data
          ? extractTimeSeriesDef(dimension.data, workspaceTimeSeries)
          : undefined
        return (
          <div key={index} className="flex items-center gap-2xs">
            <Button
              variant="icon"
              className="!px-xs !py-2xs"
              icon={light('pencil')}
              iconSize="small"
            />
            <Button
              variant="icon"
              className="!px-xs !py-2xs"
              icon={light('palette')}
              iconSize="small"
            />
            <Button
              variant="icon"
              className="!px-xs !py-2xs"
              icon={light('square-sliders-vertical')}
              iconSize="small"
            />{' '}
            <Button
              variant="icon"
              className="!px-xs !py-2xs"
              icon={light('trash-alt')}
              iconSize="small"
            />
            <Icon size="regular" className="ml-xs" icon={regular('function')} />
            <div className="flex flex-col gap-2xs">
              <Text bold>
                <Icon icon={regular('sensor')} /> {timeSeries?.label}
              </Text>
              <Text variant="description" bold>
                {timeSeries?.description}
              </Text>
            </div>
          </div>
        )
      })}
    </>
  )
}
