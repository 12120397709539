import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import humanizeDuration from 'humanize-duration'
import {
  SavedSegmentation,
  Segmentation,
  SegmentationDef,
} from 'src/dex/segmentation'
import { SavedTimeRange, TimeRange, TimeRangeDef } from 'src/dex/time_range'
import { SavedTimeSeries, TimeSeries, TimeSeriesDef } from 'src/dex/time_series'
import { humanDateTime } from 'src/utility/time'

export function getAnalysisIcon(analysisType: string): IconProp {
  switch (analysisType) {
    case 'trend':
      return light('chart-line')
    case 'scatter':
      return light('chart-scatter')
    case 'parallelCoordinates':
      return light('columns-3')
    case 'overlaidSegments':
      return light('chart-mixed')
    // TODO: Add correct icon for quality analysis
    case 'qualityAnalysis':
      return light('chart-line')
    default:
      return light('chart-line')
  }
}

export function getSegmentationIcon(segmentationType: string): IconProp {
  switch (segmentationType) {
    case 'condition':
      return light('function')
    case 'valueChange':
      return light('arrow-trend-up')
    case 'slidingWindow':
      return light('calendar-clock')
    default:
      return light('function')
  }
}

export function getSeriesIcon(seriesType: string): IconProp {
  switch (seriesType) {
    case 'tagId':
      return light('sensor')
    case 'expression':
      return light('calculator-simple')
    case 'modelOutputId':
      return light('brain')
    case 'simulation':
      return light('wave-sine')
    default:
      return light('chart-mixed')
  }
}

export function getRangeIcon(rangeType: string): IconProp {
  switch (rangeType) {
    case 'static':
      return light('calendar')
    case 'relative':
      return light('reply-clock')
    default:
      return light('calendar')
  }
}

export function getTimeRangeLabel(timeRange?: TimeRangeDef): string {
  if (timeRange?.kind?.$case === 'static') {
    const duration =
      (timeRange.kind.value.end?.valueOf() ?? 0) -
      (timeRange.kind.value.start?.valueOf() ?? 0)

    return `${humanDateTime(timeRange.kind.value.start)} - ${humanDateTime(
      timeRange.kind.value.end,
    )} (~ ${humanizeDuration(duration)})`
  } else {
    // TODO: Relative time ranges don't have values set. We only get the label.
    // How can we extract the duration from the label?
  }
  return '-'
}

const dependencyColors = ['#FF23FF', '#321CFF', '#FF662B', '#9720FF', '#FF2960']

export function getColorForDependency(dependency: string): string {
  const alphabetIndex =
    dependency.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0)
  const colorIndex = alphabetIndex % dependencyColors.length
  return dependencyColors[colorIndex]
}

export function extractTimeRangeDef(
  timeRange: TimeRange,
  workspaceTimeRanges: SavedTimeRange[],
): TimeRangeDef | undefined {
  return timeRange.kind?.$case === 'trRef'
    ? workspaceTimeRanges.find(tr => tr.id === timeRange.kind?.value)?.trDef
    : timeRange.kind?.$case === 'trDef'
    ? timeRange.kind.value
    : undefined
}

export function extractSegmentationDef(
  segmentation: Segmentation,
  workspaceSegmentations: SavedSegmentation[],
): SegmentationDef | undefined {
  return segmentation?.kind?.$case === 'segDef'
    ? segmentation.kind.value
    : workspaceSegmentations.find(s => s.id === segmentation?.kind?.value)
        ?.segDef
}

export function extractTimeSeriesDef(
  timeSeries: TimeSeries,
  workspaceTimeSeries: SavedTimeSeries[],
): TimeSeriesDef | undefined {
  return timeSeries.kind?.$case === 'tsDef'
    ? timeSeries.kind.value
    : workspaceTimeSeries.find(ts => ts.id === timeSeries.kind?.value)?.tsDef
}
