import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AggregationMap, IntrinsicMap } from 'data-explorer/data-explorer.types'
import { Button, Icon, Text } from 'src/components/ui'
import { PCDimensionSeg } from 'src/dex/analysis'
import { SegmentProperty } from 'src/dex/segment_series'
import { SavedTimeSeries } from 'src/dex/time_series'

type Props = {
  dimensions: PCDimensionSeg[]
  workspaceTimeSeries: SavedTimeSeries[]
}

export function SegmentDimensionsDisplay({
  dimensions,
  workspaceTimeSeries,
}: Props): JSX.Element {
  return (
    <>
      {dimensions.map((dimension, index) => {
        const data = getAxisFunctionAndTimeSeries(
          dimension.data,
          workspaceTimeSeries,
        )
        return (
          <div key={index} className="flex items-center gap-2xs">
            <Button
              variant="icon"
              className="!px-xs !py-2xs"
              icon={light('pencil')}
              iconSize="small"
            />
            <Button
              variant="icon"
              className="!px-xs !py-2xs"
              icon={light('palette')}
              iconSize="small"
            />
            <Button
              variant="icon"
              className="!px-xs !py-2xs"
              icon={light('square-sliders-vertical')}
              iconSize="small"
            />{' '}
            <Button
              variant="icon"
              className="!px-xs !py-2xs"
              icon={light('trash-alt')}
              iconSize="small"
            />
            <Icon size="regular" className="ml-xs" icon={regular('function')} />
            <Text bold>
              <span className="uppercase">{data.function}</span>(
              {data.timeSeries})
            </Text>
          </div>
        )
      })}
    </>
  )
}

function getAxisFunctionAndTimeSeries(
  dim: SegmentProperty | undefined,
  workspaceTimeSeries: SavedTimeSeries[],
): {
  function?: string
  timeSeries?: string
} {
  switch (dim?.kind?.$case) {
    case 'tsAgg': {
      const agg = AggregationMap[dim.kind.value.agg]
      const timeSeries = dim.kind.value.ts
      if (timeSeries?.kind?.$case === 'tsRef') {
        const ts = workspaceTimeSeries.find(
          ts => ts.id === timeSeries.kind?.value,
        )
        return {
          function: agg,
          timeSeries: ts?.tsDef?.label || 'Unknown time series',
        }
      } else {
        return {
          function: agg,
          timeSeries: timeSeries?.kind?.value.label || 'Unknown time series',
        }
      }
    }
    case 'intrinsicProperty': {
      return {
        function: IntrinsicMap[dim.kind.value],
        timeSeries: 'Segment',
      }
    }
  }
  return { function: undefined, timeSeries: undefined }
}
