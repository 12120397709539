import {
  extractSegmentationDef,
  extractTimeRangeDef,
} from 'data-explorer/data-explorer.utils'
import { Accordion } from 'src/components/ui'
import {
  ParallelCoordinates,
  PCDimensionsSeg,
  PCDimensionsTs,
} from 'src/dex/analysis'
import { SavedSegmentation } from 'src/dex/segmentation'
import { SavedTimeRange, TimeRangeDef } from 'src/dex/time_range'
import { SavedTimeSeries } from 'src/dex/time_series'
import { SegmentationDisplay, TimeRangeDisplay } from '../../components'
import {
  SegmentDimensionsDisplay,
  TimeSeriesDimensionsDisplay,
} from './components'

type Props = {
  analysis: ParallelCoordinates
  workspaceTimeRanges: SavedTimeRange[]
  workspaceSegmentations: SavedSegmentation[]
  workspaceTimeSeries: SavedTimeSeries[]
}

export function ParalelCoordinatesAnalysis({
  analysis,
  workspaceTimeRanges,
  workspaceSegmentations,
  workspaceTimeSeries,
}: Props): JSX.Element {
  const timeRange = analysis.timeRange
    ? extractTimeRangeDef(analysis.timeRange, workspaceTimeRanges)
    : undefined

  if (analysis.dimensions) {
    switch (analysis.dimensions.$case) {
      case 'segDims':
        return (
          <ParalelCoordinatesWithSegmentDims
            analysis={analysis}
            timeRange={timeRange}
            segDims={analysis.dimensions.value}
            workspaceSegmentations={workspaceSegmentations}
            workspaceTimeSeries={workspaceTimeSeries}
            workspaceTimeRanges={workspaceTimeRanges}
          />
        )
      case 'tsDims':
        return (
          <ParalelCoordinatesWithTimeSeriesDims
            analysis={analysis}
            timeRange={timeRange}
            tsDims={analysis.dimensions.value}
            workspaceTimeSeries={workspaceTimeSeries}
            workspaceTimeRanges={workspaceTimeRanges}
            workspaceSegmentations={workspaceSegmentations}
          />
        )
    }
  }

  return <></>
}

type SegmentProps = Props & {
  timeRange: TimeRangeDef | undefined
  segDims: PCDimensionsSeg
}

function ParalelCoordinatesWithSegmentDims({
  timeRange,
  segDims,
  workspaceSegmentations,
  workspaceTimeSeries,
}: SegmentProps): JSX.Element {
  const segment = segDims.segmentation
    ? extractSegmentationDef(segDims.segmentation, workspaceSegmentations)
    : undefined
  return (
    <div className="flex flex-col gap-s">
      <Accordion label="Line Selection" labelVariant="content" initialIsOpen>
        <div className="flex flex-col gap-s">
          {timeRange && <TimeRangeDisplay timeRange={timeRange} />}
          {segment && (
            <SegmentationDisplay
              segmentation={segment}
              timeSeries={workspaceTimeSeries}
            />
          )}
        </div>
      </Accordion>

      <Accordion
        label={`Dimensions (${segDims.dimensions.length})`}
        labelVariant="content"
        initialIsOpen
      >
        <div className="flex flex-col gap-s">
          <SegmentDimensionsDisplay
            dimensions={segDims.dimensions}
            workspaceTimeSeries={workspaceTimeSeries}
          />
        </div>
      </Accordion>
    </div>
  )
}

type TimeSeriesProps = Props & {
  tsDims: PCDimensionsTs
  timeRange: TimeRangeDef | undefined
}

function ParalelCoordinatesWithTimeSeriesDims({
  tsDims,
  timeRange,
  workspaceTimeSeries,
}: TimeSeriesProps): JSX.Element {
  return (
    <div className="flex flex-col gap-s">
      <Accordion label="Line Selection" labelVariant="content" initialIsOpen>
        <div className="flex flex-col gap-s">
          {timeRange && <TimeRangeDisplay timeRange={timeRange} />}
        </div>
      </Accordion>
      <Accordion
        label={`Dimensions (${tsDims.dimensions.length})`}
        labelVariant="content"
        initialIsOpen
      >
        <div className="flex flex-col gap-s">
          <div className="flex flex-col gap-s">
            <TimeSeriesDimensionsDisplay
              dimensions={tsDims.dimensions}
              workspaceTimeSeries={workspaceTimeSeries}
            />
          </div>
        </div>
      </Accordion>
    </div>
  )
}
